import { ReportUsersService } from '@common/services/report-users.service';
import { SafeHtmlPipe } from './pipes/sanitize-html.pipe';

import { StringDefaultPipe } from './pipes/string-default.pipe';
import { SubstringPipe } from './pipes/substring.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BackButtonModule } from '@mt-ng2/back-button-module';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

import { KeyboardShortcutModule, KeyboardShortcutService } from '@mt-ng2/keyboard-shortcuts-module';
import { CommonService } from './services/common.service';

import { AdvancedReportingModuleConfigToken, AdvancedReportingModuleUserServiceToken } from '@mt-ng2/advanced-reporting-module';
import { AuditLoggingModuleConfigToken, AuditLoggingModuleUserServiceToken } from '@mt-ng2/audit-logging-module';
import { DynamicFormModuleConfigToken } from '@mt-ng2/dynamic-form';
import { EntityListModuleConfigToken, IEntityListModuleConfig } from '@mt-ng2/entity-list-module';
import { ModalModule } from '@mt-ng2/modal-module';
import { NavModule } from '@mt-ng2/nav-module';
import { NgxBarcodeComponent } from './components/ngx-barcode/ngx-barcode.component';
import { PurchaseOrderHeaderComponent } from './components/purchase-order-header/purchase-order-header.component';
import { auditLogModuleConfig } from './configs/audit-log.config';
import { reportingModuleConfig } from './configs/reporting.config';
import { AuditLogUserService } from './services/audit-log-user.service';
import { CustomPaginationComponent } from './components/custom-pagination/custom-pagination.component';
import { StackableModalComponent } from './components/stackable-modal/stackable-modal.component';

export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 20,
};

/**
 * Shared Module contains all imports and exports that are commonly used in all other modules.
 * Since this is imported into the AppModule, only things that are needed on startup should be
 * included.
 *
 * Is imported into nearly all other modules, including the root module "AppModule".  forRoot
 * is only called from AppModule.
 *
 * Tip: If the import/export should only be included in feature modules, than consider putting
 * it into "AdminPortalFeatureModule" instead.  If the import/export is only needed at the AppModule level
 * and rarely/never needs to be imported into any other modules, than consider putting it into
 * "AppModule" instead.
 */
@NgModule({
    declarations: [SubstringPipe, YesNoPipe, StringDefaultPipe, SafeHtmlPipe, PurchaseOrderHeaderComponent, NgxBarcodeComponent, CustomPaginationComponent, StackableModalComponent],
    exports: [
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskDirective,
        NgxMaskPipe,
        ModalModule,
        MtDisableDuringHttpCallsModule,
        BackButtonModule,
        KeyboardShortcutModule,
        NavModule,
        SubstringPipe,
        YesNoPipe,
        StringDefaultPipe,
        SafeHtmlPipe,
        PurchaseOrderHeaderComponent,
        NgxBarcodeComponent,
        CustomPaginationComponent,
        StackableModalComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskDirective,
        NgxMaskPipe,
        ModalModule,
        MtDisableDuringHttpCallsModule,
        BackButtonModule,
        KeyboardShortcutModule,
        NavModule,
    ],
})
export class AdminPortalSharedModule {
    static forRoot(): any {
        return {
            ngModule: AdminPortalSharedModule,
            providers: [
                KeyboardShortcutService,
                { provide: EntityListModuleConfigToken, useValue: entityListModuleConfig },
                { provide: DynamicFormModuleConfigToken, useValue: { commonService: CommonService } },
                { provide: AuditLoggingModuleConfigToken, useValue: auditLogModuleConfig },
                { provide: AuditLoggingModuleUserServiceToken, useExisting: AuditLogUserService },
                { provide: AdvancedReportingModuleConfigToken, useValue: reportingModuleConfig },
                { provide: AdvancedReportingModuleUserServiceToken, useExisting: ReportUsersService },

                SubstringPipe,
                YesNoPipe,
                StringDefaultPipe,
                SafeHtmlPipe,
            ],
        };
    }
}
