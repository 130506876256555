import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { IUser } from '@model/interfaces/user';
import { IMetaItem, IPhone } from '@model/interfaces/base';
import { ICreateUserPayload } from '@model/interfaces/custom/create-user-payload';
import { IAddress } from '@mt-ng2/dynamic-form';
import { map } from 'rxjs/operators';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IInventoryReportColumnConfig } from '@model/interfaces/custom/inventory-report-column-config';

@Injectable({
    providedIn: 'root',
})
export class UserService extends BaseService<IUser> {
    private emptyUser: IUser = {
        AuthUserId: 0,
        Email: null,
        FirstName: null,
        Id: 0,
        IsSiteInTestMode: false,
        LastName: null,
        Version: null,
    };

    constructor(public http: HttpClient) {
        super('/users', http, null, { entityName: 'User', titlePrefix: 'User' });
    }

    formatTitleText(user: IUser): void {
        this.setTitle(`User: ${user.FirstName}  ${user.LastName}`);
    }

    getAllAsMetaItems(): Observable<IMetaItem[]> {
        return this.http.get<IMetaItem[]>(`/options/users`);
    }

    getSearchFilterItems(): Observable<MtSearchFilterItem[]> {
        return this.getAllAsMetaItems().pipe(map((users) => users.map((user) => ({ Item: user, Selected: false } as MtSearchFilterItem))));
    }

    getEmptyUser(): IUser {
        return { ...this.emptyUser };
    }

    createUser(data: ICreateUserPayload): Observable<number> {
        return this.http.post<number>(`/users/create`, data);
    }

    saveAddress(userId: number, address: IAddress): Observable<number> {
        if (!address.Id) {
            address.Id = 0;
            return this.http.post<number>(`/users/${userId}/address`, address);
        } else {
            return this.http.put<number>(`/users/${userId}/address`, address);
        }
    }

    deleteAddress(userId: number): Observable<object> {
        return this.http.delete(`/users/${userId}/address`, {
            responseType: 'text' as 'json',
        });
    }

    savePhones(userId: number, phones: IPhone): any {
        return this.http.put<number>(`/users/${userId}/phones`, phones);
    }

    savePhoto(userId: number, photo: File): any {
        const formData: FormData = new FormData();
        formData.append('file', photo, photo.name);

        return this.http.post(`/users/${userId}/pic`, formData);
    }

    deletePhoto(userId: number): any {
        return this.http.delete(`/users/${userId}/pic`);
    }

    getDefaultOrderPlacer(): Observable<IUser> {
        return this.http.get<IUser>(`/users/getDefaultOrderPlacer`);
    }

    getUserInventoryReportColumns(): Observable<IInventoryReportColumnConfig> {
        return this.http.get<IInventoryReportColumnConfig>(`/users/inventory-report-columns`);
    }

    saveUserInventoryReportColumns(obj: IInventoryReportColumnConfig): any {
        return this.http.post(`/users/inventory-report-columns`, obj, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        });
    }
}
